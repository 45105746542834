@import "variables";

/* You can add global styles to this file, and also import other style files */
body {
}

// the following 2 definitions are needed to have a dropdown enabled over the cell value!
.ag-row-focus {
  z-index: 999;
}

// this disables the cell selection
.ag-cell-focus, .ag-cell {
  border: none !important;
}

.ag-theme-alpine {
  --ag-value-change-value-highlight-background-color: var(--ag-selected-row-background-color);
  --ag-font-family: $wsim-font-default;
}

.cellCenter .ag-selection-checkbox {
  margin: 0;
}

.top-right-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.ag-menu {
  z-index: 999 !important;
}

.ag-cell {
  overflow: visible !important;
}

.ag-popup-editor {
  z-index: 999;
}

.ag-popup-child {
  z-index: 999;
}

.navbar {
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(90deg, #0007B9 0%, #009DFF 53.5%, #3BE0AF 100%) 1;
}

.btn-primary {
  color: $wsim-white !important;
}

.btn-light {
  color: $wsim-logo-darkblue;
}

.bg-light {
  background-color: $wsim-gray-1 !important;
}


// devexpress changes (which are currently not possible through component SCSS so it is located here currently!)
.dx-radiobutton {
  padding-top: 0.5em;
  border-bottom: 1px solid $wsim-gray-2;
  padding-bottom: 0.5em;
}

.dx-radiobutton:last-child {
  border-bottom: none;
}

// fill up the header space of the dx-datagrid
.dx-datagrid-text-content {
  width: 100%;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row td {
  background-color: $wsim-gray-2;
}

// change radio button size and color to match our style
.dx-radiobutton-icon {
  height: 16px;
  width: 16px;
  border: 2px solid $wsim-logo-lightblue;
}

// change radio button selected style to correct color and the correct border to the outline
.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background-color: $wsim-logo-lightblue;
  border-width: 3px;
}

// try to override dx-datagrid font with out default font!
.dx-datagrid {
  font: $wsim-font-default;
}

// Fix of DevExtreme Tooltips not visible
.dxc-tooltip {
  z-index: 2000;
}

// Reduced horizontal cell padding
.ag-theme-alpine {
  --ag-cell-horizontal-padding: 8px;
}
