/** wsim colors **/
$wsim-logo-lightblue: #009dff;
$wsim-logo-darkblue: #003fa5;
$wsim-vibrant-blue: #000475;
$wsim-nightblue: #000e2e;
$wsim-blue-light: #edf4fa;
$wsim-green: #3be0af;
$wsim-green-dark: #20B689;
$wsim-red: #DC3545;
$wsim-yellow: #F1C40F;

$wsim-blue-1: #E0F0FE;
$wsim-blue-3: #B3D8FA;

$wsim-white: #ffffff;
$wsim-black: #0a0f1c;

$wsim-gray-1: #f7f7fb;
$wsim-gray-2: #e9e9ef;
$wsim-gray-3: #dfdfe7;
$wsim-gray-4: #adadb2;
$wsim-gray-5: #7c7c80;
$wsim-gray-6: #525254;

$wsim-font-headings: "Space Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$wsim-font-default: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$wsim-online: #26ae60;
$wsim-offline: $wsim-gray-3;


/** bootstrap overwrites definitions **/
$primary: $wsim-logo-lightblue;
$secondary: $wsim-logo-darkblue;
$light: $wsim-blue-light;
$danger: $wsim-red;

$link-decoration: underline;

$badge-border-radius: 0.35em;

/** add more space to top & bottom of the button **/
$input-btn-padding-y: 0.5em;

$font-family-base: $wsim-font-default;

$headings-color: $wsim-logo-darkblue;
$headings-font-family: $wsim-font-headings;
$headings-font-weight: 600;

$form-label-color: $wsim-black;
$form-label-font-weight: 600;

// These custom color are added to default color palette and generates e.g. text-wsim-gray
$custom-colors: (
  "wsim-gray": $wsim-gray-5,
  "wsim-gray-2": $wsim-gray-2,
  "wsim-gray-3": $wsim-gray-3,
  "wsim-gray-4": $wsim-gray-4,
  "wsim-green": $wsim-green,
  "wsim-green-dark": $wsim-green-dark,
  "online": $wsim-online,
  "offline": $wsim-offline
);
